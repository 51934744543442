import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'


import blog from '../assets/images/ecommerce/blog.png'

import pao from '../assets/images/ecommerce/pao.png'
import laverne from '../assets/images/ecommerce/laverne.png'


const Blog = (props) => (
    <Layout>
        <Helmet>
            <title>Blogs</title>
            <meta name="description" content="Blog Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Blogs</h1>
                    </header>
                    <span className="image main"><img src={blog} alt="blog" /></span>
                    <p>Blog is a tool that has the potential to help a company gain authority, appear at the top of search engines and, of course, sell more.</p>
                    
                </div>
            </section>
            <section id="one" className="tiles">
                       
                        {/* <article style={{backgroundImage: `url(${bulldog})`}}>
                            <header className="major">
                                <h3>Coffee Shop</h3>
                            </header>
                            <Link to="https://bulldogcoffee.square.site/" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${sooncard})`}}>
                            <header className="major">
                                <h3>More to come! </h3>       
                            </header>
                            <Link to="/" className="link primary"></Link>
                        </article> */}
                        <article style={{backgroundImage: `url(${laverne})`}}>
                            <header className="major">
                                <h3>Store Blog</h3>       
                            </header>
                            <Link to="https://lavernefantasticfinds-9343b.netlify.app" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pao})`}}>
                            <header className="major">
                                <h3>Personal Blog</h3>
                                <p>Being Updated!</p>
                            </header>
                            <Link to="https://pao-blog.vercel.app/" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
            </section>
        </div>

    </Layout>
)

export default Blog